import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateCompanyData } from '../actions';
import { closePopup } from '../actions/popup';

import { BASE_ZINDEX } from '../popup-factory';
import { oauth } from '../utils';
import { ShopStatus } from '../models/Status';

import Loading from './Loading';

import { window } from '../global';

const ShopRow = ({ issue, close, disable, deactivate }) => {
  const viewShopButton = <a className="button tiny" href={`/shop/${issue.shop_number}`} target="_blank" style={{ margin: '0.25rem' }}>View Shop</a>;
  const viewClientButton = issue.client_id &&
    <a className="button tiny" href={`/client.php?id=${issue.client_id}`} target="_blank" style={{ margin: '0.25rem' }}>View Client</a>;
  const closeButton = issue.status_name === 'Open' &&
    <button className="button tiny" style={{ margin: '0.25rem' }} onClick={() => close(issue)}>Close Shop</button>;
  const disableCreditCardButton = 1 == issue.allow_credit_card_checkout &&
    <button className="button tiny" style={{ margin: '0.25rem' }} onClick={() => disable(issue)}>Disable CC Checkout</button>;
  const deleteButton = <button className="button alert tiny" style={{ margin: '0.25rem' }} onClick={() => deactivate(issue)}>Delete Shop</button>;

  return (
    <tr>
      <td>
        <a href={`/shop/${issue.shop_number}`} target="_blank">#{issue.shop_number} {issue.shop_name}</a>
        {!!issue.client_id && <span> for <a href="/client.php?id=${issue.client_id}" target="_blank">{issue.client_name}</a> </span>}
      </td>
      <td>{viewShopButton}{viewClientButton}{closeButton}{disableCreditCardButton}{deleteButton}</td>
    </tr>
  );
};

const AvalaraShopIssuesPopup = ({ company_id, avalara_issues, index }) => {
  const [issues, setIssues] = useState(avalara_issues);
  const [checking, setChecking] = useState(false);

  const dispatch = useDispatch();

  const deleteShop = issue => {
    if (!window.confirm(`Are you sure you sure you want to delete shop #${issue.shop_number} ${issue.shop_name}?`)) {
      return;
    }
    oauth('DELETE', `shop/${issue.shop_id}`, {}).then(
      ({ json }) => setIssues(issues.filter(i => i.shop_id !== issue.shop_id))
    );
  };

  const disableCreditCard = issue => {
    oauth('PUT', `shop/${issue.shop_id}`, { allow_credit_card_checkout: 0 }).then(
      ({ json }) => setIssues(issues.filter(i => i.shop_id !== issue.shop_id))
    );
  };

  const closeShop = issue => {
    oauth('PUT', `shop/${issue.shop_id}`, { status_id: ShopStatus.Closed }).then(
      ({ json }) => setIssues(issues.filter(i => i.shop_id !== issue.shop_id))
    );
  };

  const recheckIssues = () => {
    setChecking(true);
    dispatch(updateCompanyData(company_id, { avalara_testing: false })).then(
      ({ json }) => dispatch(closePopup()),
      ({ json }) => {
        setChecking(false);
        setIssues(json.avalara_errors);
      }
    );
  };

  const onClosePopup = () => dispatch(closePopup());

  return (
    <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', maxHeight: '90vh' }}>
      <div className="row small-12 columns">
        <h3>Shops with invalid Shipping Addresses</h3>
        <p>To move out of Avalara test mode you need to do one of the following for each of these shops.</p>
        <ol>
          <li>Update the shipping addresses, or</li>
          <li>Disable credit card checkout, or</li>
          <li>Close the shop, or</li>
          <li>Delete the shop</li>
        </ol>
        <a className="button" style={{ position: 'absolute', right: '6rem', top: '1rem' }} onClick={recheckIssues}>Recheck</a>
        <a className="button" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={onClosePopup}>Close</a>
      </div>
      <div style={{ overflowY: 'auto', height: '45vh', width: '100%',  }}>
        {checking ?
        <Loading />
        : <table style={{ borderStyle: 'hidden' }}>
          <tbody>
            {issues.map(i => <ShopRow key={i.shop_id} issue={i} deactivate={deleteShop} disable={disableCreditCard} close={closeShop} />)}
	  </tbody>
        </table>}
      </div>
    </div>
  );
};
export default AvalaraShopIssuesPopup;
